export const speditors = [
  {
    translationCode: 'speditors.polishPost',
    code: 'PocztaPolska',
    service: '',
    logo: 'pocztapolska_logo.png',
  },
  {
    translationCode: 'speditors.orlenPaczka',
    code: 'OrlenPaczka',
    service: '',
    logo: 'orlenPaczka_logo.png',
  },
  {
    translationCode: 'speditors.inpost',
    code: 'InPost',
    service: 'courier',
    logo: 'inpost_logo.png',
  },
  {
    translationCode: 'speditors.dhl',
    code: 'DHL',
    service: '',
    logo: 'dhl_logo.png',
  },
  {
    translationCode: 'speditors.dHLParcelShop',
    code: 'DHLParcelShop',
    service: '',
    logo: 'dHLParcelShop_logo.png',
  },
  // {
  //   translationCode: 'speditors.dHLExpress',
  //   code: 'DHLExpress',
  //   service: '',
  //   logo: 'dhlexpress_logo.png',
  // },
  {
    translationCode: 'speditors.allgro',
    code: 'Allegro',
    service: '',
    logo: 'allegro_logo.png',
  },
  {
    translationCode: 'speditors.dpd',
    code: 'DPD',
    service: '',
    logo: 'dpd_logo.png',
  },
  {
    translationCode: 'speditors.gls',
    code: 'GLS',
    service: '',
    logo: 'gls_logo.png',
  },
  // {
  //   translationCode: 'speditors.pekaes',
  //   code: 'Pekaes',
  //   service: '',
  //   logo: 'geodis_logo.png',
  // },
  // {
  //   translationCode: 'speditors.ups',
  //   code: 'UPS',
  //   service: '',
  //   logo: 'ups_logo.png',
  // },
  // {
  //   translationCode: 'speditors.dbschenker',
  //   code: 'DBSchenker',
  //   service: '',
  //   logo: 'dbschenker_logo.png',
  // },
  // {
  //   translationCode: 'speditors.fedex',
  //   code: 'Fedex',
  //   service: '',
  //   logo: 'fedex_logo.png',
  // },
  // {
  //   translationCode: 'speditors.fedexdomestic',
  //   code: 'Fedex Domestic',
  //   service: '',
  //   logo: 'fedex_logo.png',
  // },
];
